import styled, { css } from 'styled-components';

import { PageType } from '../shared/constants/Contentful.constants';

import mq from './breakpoints';
import Colors from './colors';
import Spacings from './Spacings';

export const H1Css = css`
  font-family: ${({ theme }) => theme.fontCondensed};
  font-size: ${({ theme }) => theme.h1.fontSize.S};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.h1.lineHeight.S};
  margin: 0 0 ${Spacings.XS} 0;

  ${mq.from.breakpoint.L`
    font-size: ${({ theme }) => theme.h1.fontSize.L};
    line-height: ${({ theme }) => theme.h1.lineHeight.L};
  `}
`;

export const H1SemiBoldCss = css`
  font-family: ${({ theme }) => theme.fontSemibold};
  font-size: ${({ theme }) => theme.h1.fontSize.S};
  font-style: normal;
  font-weight: 700;
  line-height: ${({ theme }) => theme.h1.lineHeight.S};
  margin: 0 0 ${Spacings.XS} 0;

  ${mq.from.breakpoint.L`
    font-size: ${({ theme }) => theme.h1.fontSize.L};
    line-height: ${({ theme }) => theme.h1.lineHeight.L};
  `}
`;

const H2Css = css`
  font-family: ${({ theme }) => theme.fontCondensed};
  font-size: ${({ theme }) => theme.h2?.fontSize?.S || '28px'};
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0em;
  line-height: ${({ theme }) => theme.h2?.lineHeight?.S || '32px'};
  margin-bottom: ${Spacings.S};
  word-break: break-word;

  ${mq.from.breakpoint.L`
    font-size: ${({ theme }) => theme.h2?.fontSize?.L || '32px'};
    line-height: ${({ theme }) => theme.h2?.lineHeight?.L || '40px'};
  `}
`;

export const H3Css = css`
  font-family: ${({ theme }) => theme.fontCondensed};
  font-size: ${({ theme }) => theme.h3.fontSize.S};
  font-style: normal;
  font-weight: 400;
  line-height: ${({ theme }) => theme.h3.lineHeight.S};

  ${mq.from.breakpoint.L`
    font-size: ${({ theme }) => theme.h3.fontSize.L};
    line-height: ${({ theme }) => theme.h3.lineHeight.L};
  `}
`;

export const H1 = styled.h1`
  color: inherit;
  ${H1Css};
`;

export const H2 = styled.h2`
  color: inherit;
  ${H2Css}
`;

export const ProductPageH2 = styled.h2`
  font-family: ${({ theme }) => theme.fontCondensed};
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  line-height: 40px;
  margin: 0 0 ${Spacings.XL};

  ${mq.from.breakpoint.L`
    font-size: 48px;
    line-height: 56px;
    margin: 0 0 64px;
    text-align: center;
  `}
`;

export const H3 = styled.h3`
  color: inherit;
  ${H3Css}
`;

export const H4 = styled.h4`
  font-family: ${({ theme }) => theme.fontRegular};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 ${Spacings.XS} 0;

  ${mq.from.breakpoint.L`
    font-size: 20px;
    line-height: 24px;
  `}
`;

export const H5 = styled.h5`
  color: ${Colors.abnMutedGreen400};
  font-family: ${({ theme }) => theme.fontRegular};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;

export const IntroText = styled.span`
  color: inherit;
  font-family: ${({ theme }) => theme.fontRegular};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: 0;

  h2 {
    color: inherit;
    font-family: ${({ theme }) => theme.fontCondensed};
    font-size: 28px;
    font-weight: 300;
    line-height: 32px;
    margin-block-end: 0px;
    margin-block-start: 0px;
    margin-bottom: ${Spacings.XS};
    margin-top: 0;
  }
`;

export const Body = styled.div<{ hasCustomLinkStyles?: boolean }>`
  color: inherit;
  font-family: ${({ theme }) => theme.fontRegular};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  p ~ ul {
    padding-top: ${Spacings.S};
  }

  p:first-of-type {
    margin: 0;
  }

  ${({ hasCustomLinkStyles, theme }) =>
    !hasCustomLinkStyles
      ? `a {
    color: ${theme.colors.abnGreen500};
    &:hover {
      color: ${theme.colors.abnGreen600};
    }
    &:active {
      color: ${theme.colors.abnGreen670};
    }
  }`
      : ''}

  p:last-of-type {
    margin-bottom: 0;
  }

  h2 {
    ${H2Css}
  }

  h3 {
    ${H3Css}
  }

  ul {
    margin-top: 0;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

export const SmallText = styled.div`
  font-family: ${({ theme }) => theme.fontRegular};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const Label = styled.label`
  color: ${Colors.abnMutedGreen400};
  display: block;
  font-family: ${({ theme }) => theme.fontSemibold};
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ theme }) => theme.label.fontWeight};
  line-height: 20px;

  input,
  textarea {
    display: block;
  }
`;

export const Legend = styled.legend`
  color: ${Colors.abnMutedGreen400};
  display: block;
  font-family: ${({ theme }) => theme.fontSemibold};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding: 0;
`;

export const LabelLight = styled.span`
  font-weight: 400;
`;

export const HeroSubtitle = styled.h2`
  color: inherit;
  font-family: ${({ theme }) => theme.fontCondensed};
  font-size: 28px;
  font-weight: 300;
  line-height: 32px;
  margin-block-end: 0px;
  margin-block-start: 0px;
  margin-bottom: ${Spacings.XS};
  margin-top: 0;

  ${mq.from.breakpoint.L`
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
  `}
`;

export const IntroCopyStyles = css`
  color: inherit;
  font-family: ${({ theme }) => theme.fontRegular};
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;

export const ButtonLabel = css`
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  word-break: break-word;
`;

export const SpanBold = styled.span`
  font-family: ${({ theme }) => theme.fontCondensed};
  weight: 700;
`;

export const AbsoluteTop = css`
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const DefaultHeaderTitle = styled(H1)`
  margin: 88px auto ${Spacings.M};
  text-align: center;
  width: 100%;

  ${mq.from.breakpoint.M`
    max-width: 80vw;
    margin: 64px auto 48px;
  `}

  ${mq.from.breakpoint.L`
    margin: 96px auto 48px;
    max-width: 60vw;
  `}

  ${mq.from.breakpoint.XL`
    font-size: 48px;
    line-height: 56px;
  `}
`;

export const StyledContent = styled.div<{ type?: string }>`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
  width: inherit;

  ${mq.from.breakpoint.L`
    margin-bottom: 80px;
  `}

  > * {
    margin-top: ${Spacings.XXXL};

    ${mq.from.breakpoint.L`
      margin-top: 80px;
    `}

    &:first-child {
      ${({ type }) =>
        type === PageType.GATED_CONTACT
          ? css`
              margin-top: 0;

              ${mq.from.breakpoint.L`
                margin-top: ${Spacings.XL};
              `}
            `
          : css`
              margin-top: ${Spacings.XXXL};

              ${mq.from.breakpoint.L`
                margin-top: 80px;
              `}
            `}
    }
  }
`;

export const PriceLabel = styled.b`
  font-family: ${({ theme }) => theme.fontCondensed};
  font-size: 28px;
  font-weight: 500;
  line-height: 32px;
  margin: 0 0 ${Spacings.S};

  ${mq.from.breakpoint.L`
    font-size: 32px;  
    line-height: 40px;
  `}
`;

export const DecimalLabel = styled.span`
  font-size: 0.66em;
  position: relative;
`;

export const CardTitleCss = css`
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 32px;

  ${mq.from.breakpoint.L`
    font-size: 32px;
    line-height: 40px;
  `}
`;
